import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import ContentBanner from "../../../../V2/Sections/Banners/Content"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

export default function StoryblokContentBanner({
  blok,
}: Storyblok.BlokProps<Storyblok.ContentBanner>) {
  return (
    <ContentBanner
      text={blok.text}
      cardBackgroundColor={blok.cardColor}
      buttonLink={getUrlFromStoryblokLink(blok.primaryCTALink)}
      buttonText={blok.primaryCTAText}
      buttonBorderColor={blok.primaryCTABorderColor}
      buttonStyle={blok.primaryCTAStyle}
      imageAlt={blok.image.alt}
      imageUrl={blok.image.filename}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
